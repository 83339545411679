<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Agregar proveedor</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Name -->
        <vs-input @blur="document.name= trimString(document.name)" label="Nombre" v-model="document.name"
                  class="w-full" name="name"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('name')">{{ errors.first('name') }}</span>

        <!-- Type Document -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Tipo de documento</label>
          <v-select :options="typeDocuments" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.typeDocument" class="mb-4 md:mb-0" name="typeDocument" id="typeDocument"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('typeDocument')">{{ errors.first('typeDocument') }}</span>
        </div>

        <!-- Document -->
        <div class="mt-5">
          <vs-input @blur="document.document= trimString(document.document)" label="Documento"
                    v-model="document.document"
                    class="w-full" name="document"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('document')">{{ errors.first('document') }}</span>
        </div>

        <!-- Address -->
        <div class="mt-5">
          <vs-input @blur="document.address= trimString(document.address)" label="Dirección"
                    v-model="document.address"
                    class="w-full" name="address"
                    v-validate="'max:250'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('address')">{{ errors.first('address') }}</span>
        </div>

        <!-- Departments -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Departamento</label>
          <v-select @input="document.province = {}" label="nomDepartamento" :options="departments" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.department" class="mb-4 md:mb-0" name="department"/>
          <span class="text-danger text-sm"
                v-show="errors.has('department')">{{ errors.first('department') }}</span>
        </div>

        <!-- Provinces -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Provincia</label>
          <v-select @input="document.district = {}" label="nomProvincia" :options="filteredProvince" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.province" class="mb-4 md:mb-0" name="province"/>
          <span class="text-danger text-sm"
                v-show="errors.has('province')">{{ errors.first('province') }}</span>
        </div>

        <!-- Districts -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Distrito</label>
          <v-select label="nomDistrito" :options="filteredDistrict" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.district" class="mb-4 md:mb-0" name="district"/>
          <span class="text-danger text-sm"
                v-show="errors.has('district')">{{ errors.first('district') }}</span>
        </div>

        <!-- Email -->
        <div class="mt-5">
          <vs-input @blur="document.email= trimString(document.email)" label="Email"
                    v-model="document.email"
                    class="w-full" name="email"
                    v-validate="'email'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('email')">{{ errors.first('email') }}</span>
        </div>

        <!-- Phone -->
        <div class="mt-5">
          <vs-input @blur="document.phone= trimString(document.phone)" label="Teléfono"
                    v-model="document.phone"
                    class="w-full" name="phone"
                    v-validate="'max:30'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
        </div>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'

import { db, FieldValue, auth } from '@/firebase/firebaseConfig'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    departments: {
      type: Array,
      required: true
    },
    provinces: {
      type: Array,
      required: true
    },
    districts: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [trimString],
  data () {
    return {
      document: {
        department: {},
        province: {}
      },
      typeDocuments: [
        'DNI',
        'RUC'
      ],

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      deleteImageFlag: false
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.document = {
          department: {},
          province: {}
        }
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    filteredProvince () {
      return this.provinces.filter((province) => {
        return province.codDepartamento === this.document.department.codDepartamento
      }).sort((a, b) => {
        a.nomProvincia.localeCompare(b.nomProvincia)
      })
    },
    filteredDistrict () {
      return this.districts.filter((district) => {
        return district.codProvincia === this.document.province.codProvincia
      }).sort((a, b) => {
        a.nomDistrito.localeCompare(b.nomDistrito)
      })
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true

          let obj = {
            ...this.document,
            state: true
          }
          const doc = await db.collection('providers').add({
            ...obj,
            createdAt: FieldValue.serverTimestamp(),
            uid: auth.currentUser.uid
          })
          obj.id = doc.id
          this.$emit('add', obj)
          this.$emit('closeSidebar')
          this.progress = false
          this.$vs.notify({
            color: 'success',
            title: 'Proveedores',
            text: 'Proveedor creado correctamente.'
          })
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
